import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  Link,
} from "react-router-dom";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import queryString from "query-string";
import request from "superagent";
import ReactPlayer from "react-player";
import ReactGA from "react-ga";
import InApp from "detect-inapp";

import Privacy from "./Privacy";
import "./App.css";
import logo from "./88_logo_gl.png";
import instagramGlyph from "./beige_insta.png";
import twitterlogo from "./twitter_icon.png";
// import GimmeLoveVideo from "./ElonMusk.mp4";
import GimmeLoveVideo from "./GimmeLoveCompressed.mp4";
import GimmeLoveBGPattern from "./gimme_love_pattern.jpg";
import GimmeLoveBGSpoon from "./Gimmelovebg-min.png";
import GimmeLoveGeneratorText from "./GimmeLoveGeneratorText.png";

ReactGA.initialize("UA-164377049-1");

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${GimmeLoveBGSpoon}), url(${GimmeLoveBGPattern});
    background-repeat: no-repeat, repeat;
    background-position: center top, left top;
    background-size: 80%, 148.5px 148.5px;

    
    
    [data-custom-class='body'] {
      width: 76%;
      margin: 0em auto;
      background: none !important;
    }
    [data-custom-class='title'], [data-custom-class='title'] * {
      font-family: Arial !important;
      font-size: 26px !important;
      color: #ffffff !important;
    }
    [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
      font-family: Arial !important;
      color: #ebebeb !important;
      font-size: 14px !important;
    }
    [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
      font-family: Arial !important;
      font-size: 19px !important;
      color: #ffffff !important;
    }
    [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
      font-family: Arial !important;
      font-size: 17px !important;
      color: #ffffff !important;
    }
    [data-custom-class='body_text'], [data-custom-class='body_text'] * {
      color: #b9b9b9 !important;
      font-size: 14px !important;
      font-family: Arial !important;
    }
    [data-custom-class='link'], [data-custom-class='link'] * {
      color: #f19c30 !important;
      font-size: 14px !important;
      font-family: Arial !important;
      word-break: break-word !important;
    }

    @media only screen and (max-width: 600px) {
      background-size: 240%,148.5px 148.5px;
      background-position: 46% 0%, top left;

      [data-custom-class='body'], [data-custom-class='body'] * {
        width: 86%;
        margin: 2em auto;
        background: none !important;
      }
    }
  }
`;

const Logo = styled.img`
  width: 12%;
  margin-bottom: 1.1em;
  cursor: pointer;

  &:active {
    transform: scale(0.96);
  }
`;

const pulse = keyframes`
  from {
    transform: scale(0.98);
  }

  to {
    transform: scale(1);
  }
`;

const Loader = styled.img`
  width: 70%;
  animation: ${pulse} 1.2s linear infinite alternate both;
  @media only screen and (max-width: 600px) {
    width: 40%;
  }
`;

const ExampleVideo = styled.video`
  height: 300px;
  width: 300px;
  box-shadow: 0px 2px 40px 10px rgba(186, 97, 34, 0.8);
  border: 4px solid #161814;
  margin-top: 4em;
`;

const GenerateButton = styled.a`
  font-family: "Roboto Condensed", sans-serif;
  background-color: #161814;
  color: #e1c5b2;
  box-shadow: 0px 0px 50px 2px #ba6122;
  margin: 4em;
  padding: 1em 4em;
  font-size: 1.2em;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: -0.8px;
  /* background-image: url(${instagramGlyph});
  background-size: 1.8em;
  background-repeat: no-repeat;
  background-position: 1em center; */
  text-decoration: none;
  display: inline-block;

  @media only screen and (max-width: 600px) {
    margin: 4em 0em 2em 0em;
    padding: 1em 1em;
    font-size: 1em;

    img {
      width: 30px;
    }
  }

  img {
    width: 40px;

    margin-right: 10px;
    vertical-align: middle;
  }

  &:active {
    transform: scale(0.96);
  }
`;

const DownloadButton = styled.a`
  font-family: "Roboto Condensed", sans-serif;
  background-color: #161814;
  color: #e1c5b2;
  box-shadow: 0px 0px 50px 2px #ba6122;
  margin: 4em auto 0em auto;
  padding: 1em 6em;
  font-size: 1.2em;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: -0.8px;
  text-decoration: none;
  display: inline-block;

  @media only screen and (max-width: 600px) {
    margin: 4em 0em 2em 0em;
    font-size: 1em;

    img {
      width: 30px;
    }
  }

  &:active {
    transform: scale(0.96);
  }
`;

const HeaderImage = styled.img`
  width: 40%;
  margin-top: 2em;
  cursor: pointer;
`;

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path={`/authorize`}>
            <Authorize />
          </Route>
          <Route path="/download">
            <Download />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
        </Switch>
      </div>
      <GlobalStyle />
    </Router>
  );
}

function ClickableLogo() {
  const history = useHistory();
  function goHome() {
    history.push("/");
  }
  return (
    <Logo
      onClick={goHome.bind(null)}
      src={logo}
      alt="88Rising"
      title="88Rising"
    />
  );
}

function ClickableHeader() {
  const history = useHistory();
  function goHome() {
    history.push("/");
  }
  return (
    <HeaderImage
      onClick={goHome.bind(null)}
      src={GimmeLoveGeneratorText}
      alt="88Rising"
      title="88Rising"
    />
  );
}

const BottomLinkContainer = styled.div`
  width: 100%;

  a {
    text-decoration: none;
    color: #e1c5b2;
    font-size: 0.8em;
    margin: 5px 8px;
    font-weight: bold;
  }

  a:hover {
    color: #e8d6c9;
  }

  span {
    display: block;
    color: #e1c5b2;
    font-size: 0.7em;
    margin: 5px;
    font-weight: bold;
  }
`;

const BottomLinks = () => {
  return (
    <BottomLinkContainer>
      <Link to="/privacy">Privacy Policy</Link>
      <a
        href="https://88rising.lnk.to/GimmeLoveWE"
        target="_blank"
        rel="noopener noreferrer"
      >
        Stream "Gimme Love"
      </a>
      <a
        href="https://88rising.lnk.to/GimmeLoveMerchWE"
        target="_blank"
        rel="noopener noreferrer"
      >
        Joji Merchandise
      </a>
      <span>© 2020 88rising. All Rights Reserved.</span>
    </BottomLinkContainer>
  );
};

function Main() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="App">
      <div>
        <div>
          <ClickableHeader />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactPlayer
            url={GimmeLoveVideo}
            width="300px"
            height="300px"
            style={{
              marginTop: "4em",
              boxShadow: "0px 2px 40px 10px rgba(186, 97, 34, 0.8)",
              border: "4px solid #161814",
            }}
            playing
            playsinline
            loop
            controls
            muted
          />
        </div>
        <div>
          <GenerateButton
            href={`https://api.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${window.location.origin}/authorize&scope=user_profile,user_media&response_type=code`}
          >
            <img src={instagramGlyph} alt="Instagram Logo" />
            <span>MAKE YOUR OWN GIMME LOVE VIDEO</span>
          </GenerateButton>
        </div>
        <div>
          <ClickableLogo />
        </div>
        <BottomLinks />
      </div>
    </div>
  );
}

const LoaderText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  color: #e1c5b2;
  margin: 1em;
  font-size: 1.1em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.8px;
  animation: ${pulse} 1.2s linear infinite alternate both;
`;

const load = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`;

const ProgressBarContainer = styled.div`
  height: 12px; /* Can be anything */
  width: 42%;
  position: relative;
  background: #555;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 4px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  margin: 1em auto;

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;

const ProgressBar = styled.span`
  display: block;
  height: 100%;
  animation: ${load} 18s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #e1c5b2;
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
`;

function Authorize() {
  let history = useHistory();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    const queryStringValues = queryString.parse(window.location.search);
    const { code } = queryStringValues;

    async function generateVideo() {
      try {
        const { body } = await request.get(
          `${process.env.REACT_APP_API_URL}?code=${code}`
        );
        history.push(`/download?id=${body.id}`);
      } catch (error) {
        history.push("/?error=Could Not Generate");
      }
    }

    generateVideo();
  }, []);
  return (
    <div className="App">
      <div>
        <div>
          <HeaderImage src={GimmeLoveGeneratorText} alt="Gimme Love" />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactPlayer
            url={GimmeLoveVideo}
            width="300px"
            height="300px"
            style={{
              marginTop: "4em",
              boxShadow: "0px 2px 40px 10px rgba(186, 97, 34, 0.8)",
              border: "4px solid #161814",
            }}
            playing
            playsinline
            loop
            controls
            muted
          />
        </div>
        <div>
          <ProgressBarContainer class="meter">
            <ProgressBar></ProgressBar>
          </ProgressBarContainer>
        </div>
        <div>
          <LoaderText>Generating Love...</LoaderText>
        </div>
        <div>
          <Logo src={logo} alt="88Rising" title="88Rising" />
        </div>
        <BottomLinks />
      </div>
    </div>
  );
}

const ShareText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  color: #e8d6c9;
  font-size: 1.2em;
  /* text-transform: uppercase; */
  font-weight: bold;
  cursor: pointer;
  letter-spacing: -0.8px;
  text-decoration: none;
  display: inline-block;
  width: 66%;
  line-height: 1.4em;

  img {
    width: 18px;
    margin-left: 8px;
    margin-right: 8px;
    vertical-align: middle;
  }

  a {
    color: #e1c5b2;
    text-decoration: none;
    display: block;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
    font-size: 1em;
    a {
      display: block;
    }
  }
`;

const WarningContainer = styled.div`
  margin-bottom: 2em;
`;

const WarningText = styled.p`
  background-color: #161814;
  color: white;
  font-weight: bold;
  padding: 1em;
  margin: 0;
  position: fixed;
  left: 0;
  width: 100%;
  font-size: 0.9em;

  @media only screen and (max-width: 600px) {
    padding: 1em 0 1em 0;
  }
`;

function Download() {
  const [urls, setURLs] = useState({ mp4: "", webm: "" });
  const [showWarning, setShowWarning] = useState(false);
  const [warningText, setWarningText] = useState("");
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    const queryStringValues = queryString.parse(window.location.search);
    const downloadId = queryStringValues.id || "";
    const shareImageUrl = `${process.env.REACT_APP_LINEUP_S3_URL}${downloadId}`;
    setURLs({ mp4: shareImageUrl + ".mp4", webm: shareImageUrl + ".webm" });
    const inapp = new InApp(
      navigator.userAgent || navigator.vendor || window.opera
    );

    if (
      inapp.browser === "instagram" ||
      inapp.browser === "twitter" ||
      inapp.browser === "facebook"
    ) {
      setShowWarning(true);
      if (inapp.os === "ios") {
        setWarningText("Safari");
      } else if (inapp.os === "android") {
        setWarningText("Chrome");
      } else {
        setWarningText("your main browser");
      }
    }
  }, []);

  return (
    <div className="App">
      <div>
        {showWarning && (
          <WarningContainer>
            <WarningText>
              {`Having trouble downloading? Open this site directly in ${warningText}.`}
            </WarningText>
          </WarningContainer>
        )}
        <div>
          <ClickableHeader />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactPlayer
            url={urls.mp4}
            width="300px"
            height="300px"
            style={{
              marginTop: "4em",
              boxShadow: "0px 2px 40px 10px rgba(186, 97, 34, 0.8)",
              border: "4px solid #161814",
            }}
            playing
            playsinline
            loop
            controls
            muted
          />
        </div>
        <div>
          <DownloadButton
            href={urls.mp4}
            target="_blank"
            rel="noopener noreferrer"
            download="GimmeLove.mp4"
          >
            Download
          </DownloadButton>
        </div>
        <ShareText>
          SHARE YOUR VIDEO WITH US! TAG{" "}
          <a
            href="https://instagram.com/GimmeLoveGenerator"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagramGlyph}
              alt="@GIMMELOVEGENERATOR"
              title="@GIMMELOVEGENERATOR"
            />{" "}
            @GIMMELOVEGENERATOR
          </a>{" "}
          <a
            href="http://twitter.com/GimmeLoveGen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterlogo} alt="@GIMMELOVEGEN" title="@GIMMELOVEGEN" />
            @GIMMELOVEGEN
          </a>
        </ShareText>
        <div>
          <ClickableLogo />
        </div>
        <BottomLinks />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import ReactGA from "react-ga";
import ClickableHeader from "./ClickableHeader";

ReactGA.initialize("UA-164377049-1");

function Privacy() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div data-custom-class="body">
      <div style={{ textAlign: "center" }}>
        <ClickableHeader />
      </div>
      <div
        align="center"
        className="MsoNormal"
        data-custom-class="title"
        style={{ textAlign: "left", lineHeight: "1.5" }}
      >
        <strong>
          <span style={{ lineHeight: "22.5px", fontSize: 26 }}>
            <bdt className="block-component" />
            PRIVACY NOTICE
            <bdt className="else-block" />
          </span>
        </strong>
      </div>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <strong>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">04/17/2020</bdt>
            </span>
          </strong>
        </span>
      </p>
      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
        <br />
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Thank you for choosing to be part of our community at{" "}
            <bdt className="question">88rising Inc</bdt>
            <bdt className="block-component" /> ("
            <bdt className="block-component" />
            <strong>Company</strong>
            <bdt className="statement-end-if-in-editor" />
            ", "<strong>we</strong>", "<strong>us</strong>", or "
            <strong>our</strong>"). We are committed to protecting your personal
            information and your right to privacy. If you have any questions or
            concerns about our{" "}
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                notice
                <bdt className="else-block" />
              </span>
            </span>
            , or our practices with regards to your personal information, please
            contact us at <bdt className="question">info@88rising.com</bdt>.
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            When you visit our <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="forloop-component" />
            <bdt className="block-component" />
            <bdt className="block-component" /> Facebook application,
            <bdt className="statement-end-if-in-editor" />
            <bdt className="forloop-component" />
            <bdt className="statement-end-if-in-editor" /> and use our services,
            you trust us with your personal information. We take your privacy
            very seriously. In this{" "}
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                privacy notice
                <bdt className="else-block" />
              </span>
            </span>
            , we seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you have in
            relation to it. We hope you take some time to read through it
            carefully, as it is important. If there are any terms in this{" "}
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                privacy notice
                <bdt className="else-block" />
              </span>
              &nbsp;
            </span>
            that you do not agree with, please discontinue use of our{" "}
            <bdt className="block-component" />
            <bdt className="block-component" />{" "}
            <bdt className="block-component" />
            Apps
            <bdt className="statement-end-if-in-editor" /> and our services.
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            This{" "}
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                privacy notice
                <bdt className="else-block" />
              </span>
              &nbsp;
            </span>
            applies to all information collected through our{" "}
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="forloop-component" />
            <bdt className="block-component" />
            <bdt className="block-component" /> Facebook application,
            <bdt className="statement-end-if-in-editor" />
            <bdt className="forloop-component" />
            &nbsp;
          </span>
          <span data-custom-class="body_text">
            ("<strong>Apps</strong>"),
            <bdt className="statement-end-if-in-editor" /> and/or any related
            services, sales, marketing or events (we refer to them collectively
            in this{" "}
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                privacy notice
                <bdt className="else-block" />
              </span>
              &nbsp;
            </span>
            as the "<strong>Services</strong>").
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <strong>
            <span data-custom-class="body_text">
              Please read this{" "}
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  privacy notice
                  <bdt className="else-block" />
                </span>
                &nbsp;
              </span>
              carefully as it will help you make informed decisions about
              sharing your personal information with us.
            </span>
          </strong>
        </span>
      </p>
      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <br />
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <strong>
            <span style={{ fontSize: 19 }}>
              <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
            </span>
          </strong>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infocollect">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            1. WHAT INFORMATION DO WE COLLECT?
          </span>
        </a>{" "}
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <bdt className="block-component" />
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <a data-custom-class="link" href="#infoshare">
            2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </a>
          <bdt className="block-component">
            <span data-custom-class="body_text" />
          </bdt>
        </span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <bdt className="block-component" />
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#cookies">
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </span>
        </a>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <bdt className="statement-end-if-in-editor" />
        </span>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <bdt className="block-component" />
            <bdt className="block-component" />
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#sociallogins">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </span>
            </span>
          </span>
        </a>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
          <bdt className="block-component" />
          <bdt className="block-component" />
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#inforetain">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </span>
        </a>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <bdt className="block-component" />
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </span>
        </a>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
          <bdt className="block-component" />
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infominors">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </span>
        </a>
        <bdt className="statement-end-if-in-editor" />
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#privacyrights">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            8. WHAT ARE YOUR PRIVACY RIGHTS?
            <bdt className="block-component" />
          </span>
        </a>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#DNT">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </span>
        </a>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#caresidents">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </span>
        </a>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#policyupdates">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            11. DO WE MAKE UPDATES TO THIS POLICY?
          </span>
        </a>
      </p>
      <p style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
          </span>
        </a>
      </p>
      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <br />
        </span>
      </p>
      <p id="infocollect" style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <strong>
            <span style={{ fontSize: 19 }}>
              <span data-custom-class="heading_1">
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </span>
          </strong>
          <span style={{ fontSize: 19 }}>
            <span data-custom-class="heading_1">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
        <span data-custom-class="body_text">
          <bdt className="block-component" />
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <strong>
          <span data-custom-class="heading_2">
            <br />
            <span style={{ fontSize: 16 }}>
              Information automatically collected
            </span>
          </span>
        </strong>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <strong>
            <em>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">In Short:&nbsp;</span>
              </span>
              &nbsp;
            </em>
            &nbsp;
          </strong>
          <em>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                Some information — such as IP address and/or browser and device
                characteristics — is collected automatically when you visit our{" "}
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                Apps
                <bdt className="statement-end-if-in-editor" />.
              </span>
            </span>
          </em>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              We automatically collect certain information when you visit, use
              or navigate the <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              Apps
              <bdt className="statement-end-if-in-editor" />. This information
              does not reveal your specific identity (like your name or contact
              information) but may include device and usage information, such as
              your IP address, browser and device characteristics, operating
              system, language preferences, referring URLs, device name,
              country, location, information about how and when you use our{" "}
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              Apps
              <bdt className="statement-end-if-in-editor" /> and other technical
              information. This information is primarily needed to maintain the
              security and operation of our <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              Apps
              <bdt className="statement-end-if-in-editor" />, and for our
              internal analytics and reporting purposes.
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              Like many businesses, we also collect information through cookies
              and similar technologies. <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component" />
            </span>
          </span>
        </span>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor">
              <bdt className="block-component" />
            </bdt>
          </span>
        </span>
      </p>
      <p style={{ fontSize: 15 }}>
        <strong>
          <span style={{ fontSize: 16 }}>
            <span data-custom-class="heading_2">
              <strong>
                <span data-custom-class="heading_2">
                  <br />
                  Information collected through our Apps
                </span>
              </strong>
            </span>
          </span>
        </strong>
      </p>
      <p style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <strong>
            <em>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">In Short:</span>
              </span>
              &nbsp;
            </em>{" "}
            &nbsp;
          </strong>
          <span style={{ fontSize: 15 }}>
            <em>
              <span data-custom-class="body_text">
                We may collect information regarding your
                <bdt className="block-component">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </em>
          </span>
        </span>
        <bdt className="block-component" />
        <bdt className="forloop-component" />
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15 }}>
            <em>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </em>
            <span data-custom-class="body_text">&nbsp;</span>
          </span>
        </span>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15 }}>
            <em>
              <span data-custom-class="body_text">
                and Facebook permissions
              </span>
            </em>
          </span>
        </span>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15 }}>
            <em>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </em>
          </span>
        </span>
        <bdt className="forloop-component" />{" "}
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15 }}>
            <em>
              <span data-custom-class="body_text">when you use our apps.</span>
            </em>
          </span>
        </span>
      </p>
      <div>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            If you use our Apps, we may also collect the following information:
            <bdt className="block-component" />
          </span>
        </span>{" "}
        <bdt className="block-component">
          <bdt className="block-component">
            <bdt className="block-component" />
            <bdt className="forloop-component" />
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
            <br />
            <br />
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <em>Facebook Permissions.</em> We by default access
                    your&nbsp;
                  </span>
                </span>
              </span>
              <a
                data-custom-class="link"
                href="https://www.facebook.com/about/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">Facebook</span>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    &nbsp;basic account information, including your name, email,
                    gender, birthday, current city, and profile picture URL, as
                    well as other information that you choose to make public. We
                    may also request access to other permissions related to your
                    account, such as friends, checkins, and likes, and you may
                    choose to grant or deny us access to each individual
                    permission. For more information regarding Facebook
                    permissions, refer to the&nbsp;
                  </span>
                </span>
              </span>
              <a
                data-custom-class="link"
                href="https://developers.facebook.com/docs/facebook-login/permissions"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      Facebook Permissions Reference
                    </span>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    &nbsp;page.
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                    <bdt className="forloop-component" />
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <bdt className="block-component" />
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <strong>
                <span style={{ fontSize: 16 }}>
                  <span data-custom-class="heading_2">
                    <strong>
                      <span data-custom-class="heading_2">
                        <br />
                        Information collected from other sources
                      </span>
                    </strong>
                  </span>
                </span>
              </strong>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>{" "}
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      We may collect limited data from public databases,
                      marketing partners, <bdt className="block-component" />
                      social media platforms,{" "}
                      <bdt className="statement-end-if-in-editor" />
                      and other outside sources.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    We may obtain information about your from other sources,
                    such as public databases, joint marketing partners,
                    <bdt className="block-component" /> social media platforms
                    (such as Facebook),
                    <bdt className="statement-end-if-in-editor" /> as well as
                    from other third parties. Examples of the information we
                    receive from other sources include: social media profile
                    information
                    <bdt className="block-component" /> (your name, gender,
                    birthday, email, current city, state and country, user
                    identification numbers for your contacts, profile picture
                    URL, and any other information that you choose to make
                    public)
                    <bdt className="statement-end-if-in-editor" />; marketing
                    leads and search results and links, including paid listings
                    (such as sponsored links). We will inform you about the
                    source of information and the type of information and the
                    type of information we have collected about you within a
                    reasonable period after obtaining the personal data, but at
                    the latest within one month.
                  </span>
                </span>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                              <bdt className="statement-end-if-in-editor" />
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </bdt>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="infoshare" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      We only share information with your consent, to comply
                      with laws, to provide you with services, to protect your
                      rights, or to fulfill business obligations.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <div>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We may process or share data based on the following legal
                  basis:
                </span>
              </span>
            </div>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <strong>Consent:</strong> We may process your data if you
                    have given us specific consent to use your personal
                    information in a specific purpose.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <strong>Legitimate Interests:</strong> We may process your
                    data when it is reasonably necessary to achieve our
                    legitimate business interests.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <strong>Performance of a Contract:&nbsp;</strong>Where we
                    have entered into a contract with you, we may process your
                    personal information to fulfill the terms of our contract.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <strong>Legal Obligations:</strong> We may disclose your
                    information where we are legally required to do so in order
                    to comply with applicable law, governmental requests, a
                    judicial proceeding, court order, or legal process, such as
                    in response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements).
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>Vital Interests:</strong> We may disclose your
                    information where we believe it is necessary to investigate,
                    prevent, or take action regarding potential violations of
                    our policies, suspected fraud, situations involving
                    potential threats to the safety of any person and illegal
                    activities, or as evidence in litigation in which we are
                    involved.
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    More specifically, we may need to process your data or share
                    your personal information in the following situations:
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Vendors, Consultants and Other Third-Party Service
                        Providers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may share your data with third party vendors,
                      service providers, contractors or agents who perform
                      services for us or on our behalf and require access to
                      such information to do that work. Examples include:
                      payment processing, data analysis, email delivery, hosting
                      services, customer service and marketing efforts. We may
                      allow selected third parties to use tracking technology on
                      the <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" />, which will
                      enable them to collect data about how you interact with
                      the <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" /> over time.
                      This information may be used to, among other things,
                      analyze and track data, determine the popularity of
                      certain content and better understand online activity.
                      Unless described in this Policy, we do not share, sell,
                      rent or trade any of your information with third parties
                      for their promotional purposes.{" "}
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Business Transfers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may share or transfer your information in
                      connection with, or during negotiations of, any merger,
                      sale of company assets, financing, or acquisition of all
                      or a portion of our business to another company.
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Third-Party Advertisers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may use third-party advertising companies to
                      serve ads when you visit the{" "}
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" />. These
                      companies may use information about your visits to our
                      Website(s) and other websites that are contained in web
                      cookies and other tracking technologies in order to
                      provide advertisements about goods and services of
                      interest to you. <bdt className="block-component" />
                      <bdt className="statement-end-if-in-editor" />
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <bdt className="block-component" />
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Business Partners.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may share your information with our business
                      partners to offer you certain products, services or
                      promotions.
                    </span>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                    </span>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component">
                        <span data-custom-class="body_text" />
                      </bdt>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component">
                          <span data-custom-class="heading_1" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <p style={{ fontSize: 15 }}>
              <br />
            </p>
            <div>
              <span id="cookies" style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      We may use cookies and other tracking technologies to
                      collect and store your information.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Policy
                  <bdt className="block-component" />.
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="sociallogins" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      If you choose to register or log in to our services using
                      a social media account, we may have access to certain
                      information about you.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Our <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" /> offer you the
                    ability to register and login using your third party social
                    media account details (like your Facebook or Twitter
                    logins). Where you choose to do this, we will receive
                    certain profile information about you from your social media
                    provider. The profile Information we receive may vary
                    depending on the social media provider concerned, but will
                    often include your name, e-mail address, friends list,
                    profile picture as well as other information you choose to
                    make public. <bdt className="block-component" />
                    <bdt className="forloop-component" />
                    <bdt className="block-component" />
                    If you login using Facebook, we may also request access to
                    other permissions related to your account, such as friends,
                    check-ins, and likes, and you may choose to grant or deny us
                    access to each individual permission.
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="forloop-component" />
                          </span>
                        </span>
                      </span>
                      <bdt className="statement-end-if-in-editor">
                        <span data-custom-class="body_text" />
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We will use the information we receive only for the purposes
                  that are described in this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>
                    &nbsp;
                  </span>
                  or that are otherwise made clear to you on the{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />. Please note
                  that we do not control, and are not responsible for, other
                  uses of your personal information by your third party social
                  media provider. We recommend that you review their privacy
                  policy to understand how they collect, use and share your
                  personal information, and how you can set your privacy
                  preferences on their sites and apps.
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                              <bdt className="block-component">
                                <span data-custom-class="body_text" />
                              </bdt>
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="inforetain" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      5. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      We keep your information for as long as necessary to
                      fulfill the purposes outlined in this{" "}
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                          privacy notice
                          <bdt className="else-block" />
                        </span>
                        &nbsp;
                      </span>
                      unless otherwise required by law.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        privacy notice
                        <bdt className="else-block" />
                      </span>
                    </span>
                    , unless a longer retention period is required or permitted
                    by law (such as tax, accounting or other legal
                    requirements). No purpose in this policy will require us
                    keeping your personal information for longer than{" "}
                    <bdt className="block-component" />
                    <bdt className="question">2 years</bdt>
                    <bdt className="statement-end-if-in-editor" />.
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  it, or, if this is not possible (for example, because your
                  personal information has been stored in backup archives), then
                  we will securely store your personal information and isolate
                  it from any further processing until deletion is possible.
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </p>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <p id="infosafe" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      We aim to protect your personal information through a
                      system of organizational and technical security measures.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We have implemented appropriate technical and organizational
                  security measures designed to protect the security of any
                  personal information we process. However, please also remember
                  that we cannot guarantee that the internet itself is 100%
                  secure. Although we will do our best to protect your personal
                  information, transmission of personal information to and from
                  our <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" /> is at your own
                  risk. You should only access the services within a secure
                  environment.
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="infominors" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      7. DO WE COLLECT INFORMATION FROM MINORS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      We do not knowingly collect data from or market to
                      children under 18 years of age.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent's
                  use of the <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />. If we learn
                  that personal information from users less than 18 years of age
                  has been collected, we will deactivate the account and take
                  reasonable measures to promptly delete such data from our
                  records. If you become aware of any data we have collected
                  from children under age 18, please contact us at{" "}
                  <bdt className="block-component" />
                  <bdt className="question">info@88rising.com</bdt>
                  <bdt className="else-block" />.
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="privacyrights" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <em>
                      <bdt className="block-component" />
                      You may review, change, or terminate your account at any
                      time.
                    </em>
                  </span>
                  <bdt className="block-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    If you are resident in the European Economic Area and you
                    believe we are unlawfully processing your personal
                    information, you also have the right to complain to your
                    local data protection supervisory authority. You can find
                    their contact details here:
                  </span>
                </span>
                &nbsp;
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(48, 48, 241)" }}>
                  <span style={{ fontSize: 15 }}>
                    <a
                      data-custom-class="link"
                      href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                      target="_blank"
                    >
                      http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                    </a>
                    .
                  </span>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                  <bdt className="block-component" />
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <u>
                      <span data-custom-class="body_text">
                        Cookies and similar technologies:
                      </span>
                    </u>
                    &nbsp;
                  </strong>
                  <span data-custom-class="body_text">
                    Most Web browsers are set to accept cookies by default. If
                    you prefer, you can usually choose to set your browser to
                    remove cookies and to reject cookies. If you choose to
                    remove cookies or reject cookies, this could affect certain
                    features or services of our{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" />. To opt-out
                    of interest-based advertising by advertisers on our{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" /> visit
                  </span>
                </span>
                &nbsp;
              </span>
              <span style={{ color: "rgb(48, 48, 241)" }}>
                <span data-custom-class="body_text">
                  <a
                    data-custom-class="link"
                    href="http://www.aboutads.info/choices/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span style={{ fontSize: 15 }}>
                      http://www.aboutads.info/choices/
                    </span>
                  </a>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    . <bdt className="block-component" />
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="DNT" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      9. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. No uniform technology
                    standard for recognizing and implementing DNT signals has
                    been finalized. As such, we do not currently respond to DNT
                    browser signals or any other mechanism that automatically
                    communicates your choice not to be tracked online. If a
                    standard for online tracking is adopted that we must follow
                    in the future, we will inform you about that practice in a
                    revised version of this{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        privacy notice
                        <bdt className="else-block" />
                      </span>
                    </span>
                    .
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="caresidents" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      Yes, if you are a resident of California, you are granted
                      specific rights regarding access to your personal
                      information.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    California Civil Code Section 1798.83, also known as the
                    "Shine The Light" law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  If you are under 18 years of age, reside in California, and
                  have a registered account with the{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />, you have the
                  right to request removal of unwanted data that you publicly
                  post on the <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />. To request
                  removal of such data, please contact us using the contact
                  information provided below, and include the email address
                  associated with your account and a statement that you reside
                  in California. We will make sure the data is not publicly
                  displayed on the <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />, but please be
                  aware that the data may not be completely or comprehensively
                  removed from our systems.
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="policyupdates" style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      11. DO WE MAKE UPDATES TO THIS POLICY?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: 15 }}>
                  <em>
                    <span data-custom-class="body_text">
                      Yes, we will update this policy as necessary to stay
                      compliant with relevant laws.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We may update this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>
                    &nbsp;
                  </span>
                  from time to time. The updated version will be indicated by an
                  updated "Revised" date and the updated version will be
                  effective as soon as it is accessible. If we make material
                  changes to this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>
                  </span>
                  , we may notify you either by prominently posting a notice of
                  such changes or by directly sending you a notification. We
                  encourage you to review this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>
                    &nbsp;
                  </span>
                  frequently to be informed of how we are protecting your
                  information.
                </span>
              </span>
            </p>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="contact" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  If you have questions or comments about this policy, you may{" "}
                  <bdt className="block-component" />
                  email us at <bdt className="question">info@88rising.com</bdt>
                  <bdt className="statement-end-if-in-editor" /> or by post to:
                </span>
              </span>
            </p>
            <div>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="question">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">88rising Inc</span>
                  </span>
                  &nbsp;
                </bdt>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <bdt className="question">135 W 20th st #401</bdt>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <bdt className="question">New York</bdt>
                    <bdt className="block-component" />
                    <bdt className="block-component" />,{" "}
                    <bdt className="question">NY</bdt>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />{" "}
                    <bdt className="question">10011</bdt>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <bdt className="question">United States</bdt>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <bdt className="else-block" />
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <bdt className="block-component" />
            </div>
            <p id="contact" style={{ fontSize: 15, lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="contact" style={{ fontSize: 15 }}>
              <strong>
                <span data-custom-class="heading_1">
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </span>
              </strong>
            </p>
            <div>
              <span data-custom-class="body_text">
                Based on the laws of some countries, you may have the right to
                request access to the personal information we collect from you,
                change that information, or delete it in some circumstances. To
                request to review, update, or delete your personal information,
                please <bdt className="block-component" />
                submit a request form by
                <span data-custom-class="body_text">
                  &nbsp;
                  <span data-custom-class="body_text">
                    clicking{" "}
                    <span style={{ color: "rgb(48, 48, 241)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <a
                            data-custom-class="link"
                            href="https://app.termly.io/notify/29747aaf-c2e0-4172-93c4-046e2ab6dec1"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            here
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="block-component" />. We will respond to your
                request within 30 days.
              </span>
            </div>
          </bdt>
        </bdt>
      </div>
    </div>
  );
}

export default Privacy;

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import GimmeLoveGeneratorText from "./GimmeLoveGeneratorText.png";

const HeaderImage = styled.img`
  width: 22.91%;
  margin: 2em auto;
  cursor: pointer;
`;

function ClickableHeader() {
  const history = useHistory();
  function goHome() {
    history.push("/");
  }
  return (
    <HeaderImage
      onClick={goHome.bind(null)}
      src={GimmeLoveGeneratorText}
      alt="Gimme Love"
      title="Gimme Love"
    />
  );
}

export default ClickableHeader;
